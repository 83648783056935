/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { Accessory } from "./Accessory";
import {
  AccessoryFromJSON,
  AccessoryFromJSONTyped,
  AccessoryToJSON,
} from "./Accessory";
import type { AdditionalService } from "./AdditionalService";
import {
  AdditionalServiceFromJSON,
  AdditionalServiceFromJSONTyped,
  AdditionalServiceToJSON,
} from "./AdditionalService";
import type { BenefitCancellationCoverage } from "./BenefitCancellationCoverage";
import {
  BenefitCancellationCoverageFromJSON,
  BenefitCancellationCoverageFromJSONTyped,
  BenefitCancellationCoverageToJSON,
} from "./BenefitCancellationCoverage";
import type { BenefitCancellationCoverageStatus } from "./BenefitCancellationCoverageStatus";
import {
  BenefitCancellationCoverageStatusFromJSON,
  BenefitCancellationCoverageStatusFromJSONTyped,
  BenefitCancellationCoverageStatusToJSON,
} from "./BenefitCancellationCoverageStatus";
import type { BenefitDefinitionSummaryRead } from "./BenefitDefinitionSummaryRead";
import {
  BenefitDefinitionSummaryReadFromJSON,
  BenefitDefinitionSummaryReadFromJSONTyped,
  BenefitDefinitionSummaryReadToJSON,
} from "./BenefitDefinitionSummaryRead";
import type { BikeAdminRead } from "./BikeAdminRead";
import {
  BikeAdminReadFromJSON,
  BikeAdminReadFromJSONTyped,
  BikeAdminReadToJSON,
} from "./BikeAdminRead";
import type { BikeBenefitContractCancellationReason } from "./BikeBenefitContractCancellationReason";
import {
  BikeBenefitContractCancellationReasonFromJSON,
  BikeBenefitContractCancellationReasonFromJSONTyped,
  BikeBenefitContractCancellationReasonToJSON,
} from "./BikeBenefitContractCancellationReason";
import type { BikeBenefitContractState } from "./BikeBenefitContractState";
import {
  BikeBenefitContractStateFromJSON,
  BikeBenefitContractStateFromJSONTyped,
  BikeBenefitContractStateToJSON,
} from "./BikeBenefitContractState";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";
import type { FinancingMethods } from "./FinancingMethods";
import {
  FinancingMethodsFromJSON,
  FinancingMethodsFromJSONTyped,
  FinancingMethodsToJSON,
} from "./FinancingMethods";
import type { FundingSource } from "./FundingSource";
import {
  FundingSourceFromJSON,
  FundingSourceFromJSONTyped,
  FundingSourceToJSON,
} from "./FundingSource";
import type { Notification } from "./Notification";
import {
  NotificationFromJSON,
  NotificationFromJSONTyped,
  NotificationToJSON,
} from "./Notification";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
} from "./SupportedOrganisationCountries";
import type { Transport } from "./Transport";
import {
  TransportFromJSON,
  TransportFromJSONTyped,
  TransportToJSON,
} from "./Transport";
import type { UserSummaryExtendedRead } from "./UserSummaryExtendedRead";
import {
  UserSummaryExtendedReadFromJSON,
  UserSummaryExtendedReadFromJSONTyped,
  UserSummaryExtendedReadToJSON,
} from "./UserSummaryExtendedRead";

/**
 *
 * @export
 * @interface BikeBenefitContractProviderRead
 */
export interface BikeBenefitContractProviderRead {
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractProviderRead
   */
  deliveryDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractProviderRead
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractProviderRead
   */
  endDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractProviderRead
   */
  exceptionalEndDate?: Date;
  /**
   *
   * @type {BikeBenefitContractCancellationReason}
   * @memberof BikeBenefitContractProviderRead
   */
  cancellationReason?: BikeBenefitContractCancellationReason;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  cancellationReasonDescription?: string;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitContractProviderRead
   */
  isInvalidated?: boolean;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  bikeBenefitOrderId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  benefitDefinitionId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  benefitDefinitionTermsId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  bikeId: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  bikePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BikeBenefitContractProviderRead
   */
  currency: Currency;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  insuranceFee: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  netFixedMonthlyTaxableValue?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  monthlyMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  initialTotalPackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  netTaxablePackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  netMonthlyLeasingFee: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  netMonthlyTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  netTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  initialVatRate: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  netResidualValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  downPaymentAmount: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  extendedWarranty: number;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitContractProviderRead
   */
  isDownPaymentInsured: boolean;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  yearlyDownPaymentInsuranceFee?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  redemptionPercentage: number;
  /**
   *
   * @type {BenefitCancellationCoverage}
   * @memberof BikeBenefitContractProviderRead
   */
  cancellationCoverage?: BenefitCancellationCoverage;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  notes?: string;
  /**
   *
   * @type {FinancingMethods}
   * @memberof BikeBenefitContractProviderRead
   */
  financingMethod?: FinancingMethods;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  financierSellingPrice?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  tukirahoitusMonthlyLeasingPrice?: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  tukirahoitusInterestRatesId?: string;
  /**
   *
   * @type {FundingSource}
   * @memberof BikeBenefitContractProviderRead
   */
  fundingSource?: FundingSource;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitContractProviderRead
   */
  isEndedNotificationSent?: boolean;
  /**
   *
   * @type {Array<Notification>}
   * @memberof BikeBenefitContractProviderRead
   */
  sentNotifications?: Array<Notification>;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  id: string;
  /**
   *
   * @type {SupportedOrganisationCountries}
   * @memberof BikeBenefitContractProviderRead
   */
  benefitCountry: SupportedOrganisationCountries;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  currentFlatVatRate: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  monthlyTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  fixedMonthlyTaxableValue?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  initialMonthlyTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  initialFixedMonthlyTaxableValue?: number;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractProviderRead
   */
  leasingPeriodStartDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractProviderRead
   */
  effectiveEndDate: Date;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  totalPackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  taxablePackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  monthlyLeasingFee: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  taxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  taxablePackagePriceReduction: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  totalMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  spentMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  remainingMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  residualRatio: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  residualValue: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  discountedResidualValue: number;
  /**
   *
   * @type {BikeAdminRead}
   * @memberof BikeBenefitContractProviderRead
   */
  bike: BikeAdminRead;
  /**
   *
   * @type {Array<Accessory>}
   * @memberof BikeBenefitContractProviderRead
   */
  accessories: Array<Accessory>;
  /**
   *
   * @type {BikeBenefitContractState}
   * @memberof BikeBenefitContractProviderRead
   */
  state: BikeBenefitContractState;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  vapausCode: string;
  /**
   *
   * @type {Array<AdditionalService>}
   * @memberof BikeBenefitContractProviderRead
   */
  additionalServices: Array<AdditionalService>;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractProviderRead
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitContractProviderRead
   */
  updatedAt: Date;
  /**
   *
   * @type {Array<Transport>}
   * @memberof BikeBenefitContractProviderRead
   */
  transports: Array<Transport>;
  /**
   *
   * @type {Transport}
   * @memberof BikeBenefitContractProviderRead
   */
  deliveryTransport?: Transport;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitContractProviderRead
   */
  correctedByBikeBenefitOrderIds: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitContractProviderRead
   */
  revisedByBikeBenefitOrderIds: Array<string>;
  /**
   *
   * @type {BenefitCancellationCoverageStatus}
   * @memberof BikeBenefitContractProviderRead
   */
  cancellationCoverageStatus: BenefitCancellationCoverageStatus;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractProviderRead
   */
  remainingLeasingPeriodMonths: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  currentRedemptionProcessId?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  currentReturnProcessId?: string;
  /**
   *
   * @type {UserSummaryExtendedRead}
   * @memberof BikeBenefitContractProviderRead
   */
  user: UserSummaryExtendedRead;
  /**
   *
   * @type {BenefitDefinitionSummaryRead}
   * @memberof BikeBenefitContractProviderRead
   */
  benefitDefinition: BenefitDefinitionSummaryRead;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractProviderRead
   */
  beneficiaryEmail: string;
}

/**
 * Check if a given object implements the BikeBenefitContractProviderRead interface.
 */
export function instanceOfBikeBenefitContractProviderRead(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "deliveryDate" in value;
  isInstance = isInstance && "startDate" in value;
  isInstance = isInstance && "endDate" in value;
  isInstance = isInstance && "leasingPeriodMonths" in value;
  isInstance = isInstance && "userId" in value;
  isInstance = isInstance && "bikeBenefitOrderId" in value;
  isInstance = isInstance && "benefitDefinitionId" in value;
  isInstance = isInstance && "benefitDefinitionTermsId" in value;
  isInstance = isInstance && "bikeId" in value;
  isInstance = isInstance && "bikePrice" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "insuranceFee" in value;
  isInstance = isInstance && "monthlyMaintenanceBudget" in value;
  isInstance = isInstance && "initialTotalPackagePrice" in value;
  isInstance = isInstance && "netTaxablePackagePrice" in value;
  isInstance = isInstance && "netMonthlyLeasingFee" in value;
  isInstance = isInstance && "netMonthlyTaxableValue" in value;
  isInstance = isInstance && "netTaxableValue" in value;
  isInstance = isInstance && "initialVatRate" in value;
  isInstance = isInstance && "netResidualValue" in value;
  isInstance = isInstance && "downPaymentAmount" in value;
  isInstance = isInstance && "extendedWarranty" in value;
  isInstance = isInstance && "isDownPaymentInsured" in value;
  isInstance = isInstance && "redemptionPercentage" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "benefitCountry" in value;
  isInstance = isInstance && "currentFlatVatRate" in value;
  isInstance = isInstance && "monthlyTaxableValue" in value;
  isInstance = isInstance && "initialMonthlyTaxableValue" in value;
  isInstance = isInstance && "leasingPeriodStartDate" in value;
  isInstance = isInstance && "effectiveEndDate" in value;
  isInstance = isInstance && "totalPackagePrice" in value;
  isInstance = isInstance && "taxablePackagePrice" in value;
  isInstance = isInstance && "monthlyLeasingFee" in value;
  isInstance = isInstance && "taxableValue" in value;
  isInstance = isInstance && "taxablePackagePriceReduction" in value;
  isInstance = isInstance && "totalMaintenanceBudget" in value;
  isInstance = isInstance && "spentMaintenanceBudget" in value;
  isInstance = isInstance && "remainingMaintenanceBudget" in value;
  isInstance = isInstance && "residualRatio" in value;
  isInstance = isInstance && "residualValue" in value;
  isInstance = isInstance && "discountedResidualValue" in value;
  isInstance = isInstance && "bike" in value;
  isInstance = isInstance && "accessories" in value;
  isInstance = isInstance && "state" in value;
  isInstance = isInstance && "vapausCode" in value;
  isInstance = isInstance && "additionalServices" in value;
  isInstance = isInstance && "createdAt" in value;
  isInstance = isInstance && "updatedAt" in value;
  isInstance = isInstance && "transports" in value;
  isInstance = isInstance && "correctedByBikeBenefitOrderIds" in value;
  isInstance = isInstance && "revisedByBikeBenefitOrderIds" in value;
  isInstance = isInstance && "cancellationCoverageStatus" in value;
  isInstance = isInstance && "remainingLeasingPeriodMonths" in value;
  isInstance = isInstance && "user" in value;
  isInstance = isInstance && "benefitDefinition" in value;
  isInstance = isInstance && "beneficiaryEmail" in value;

  return isInstance;
}

export function BikeBenefitContractProviderReadFromJSON(
  json: any,
): BikeBenefitContractProviderRead {
  return BikeBenefitContractProviderReadFromJSONTyped(json, false);
}

export function BikeBenefitContractProviderReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitContractProviderRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    deliveryDate: new Date(json["delivery_date"]),
    startDate: new Date(json["start_date"]),
    endDate: new Date(json["end_date"]),
    exceptionalEndDate: !exists(json, "exceptional_end_date")
      ? undefined
      : new Date(json["exceptional_end_date"]),
    cancellationReason: !exists(json, "cancellation_reason")
      ? undefined
      : BikeBenefitContractCancellationReasonFromJSON(
          json["cancellation_reason"],
        ),
    cancellationReasonDescription: !exists(
      json,
      "cancellation_reason_description",
    )
      ? undefined
      : json["cancellation_reason_description"],
    isInvalidated: !exists(json, "is_invalidated")
      ? undefined
      : json["is_invalidated"],
    leasingPeriodMonths: json["leasing_period_months"],
    userId: json["user_id"],
    bikeBenefitOrderId: json["bike_benefit_order_id"],
    benefitDefinitionId: json["benefit_definition_id"],
    benefitDefinitionTermsId: json["benefit_definition_terms_id"],
    bikeId: json["bike_id"],
    bikePrice: json["bike_price"],
    currency: CurrencyFromJSON(json["currency"]),
    insuranceFee: json["insurance_fee"],
    netFixedMonthlyTaxableValue: !exists(
      json,
      "net_fixed_monthly_taxable_value",
    )
      ? undefined
      : json["net_fixed_monthly_taxable_value"],
    monthlyMaintenanceBudget: json["monthly_maintenance_budget"],
    initialTotalPackagePrice: json["initial_total_package_price"],
    netTaxablePackagePrice: json["net_taxable_package_price"],
    netMonthlyLeasingFee: json["net_monthly_leasing_fee"],
    netMonthlyTaxableValue: json["net_monthly_taxable_value"],
    netTaxableValue: json["net_taxable_value"],
    initialVatRate: json["initial_vat_rate"],
    netResidualValue: json["net_residual_value"],
    downPaymentAmount: json["down_payment_amount"],
    extendedWarranty: json["extended_warranty"],
    isDownPaymentInsured: json["is_down_payment_insured"],
    yearlyDownPaymentInsuranceFee: !exists(
      json,
      "yearly_down_payment_insurance_fee",
    )
      ? undefined
      : json["yearly_down_payment_insurance_fee"],
    redemptionPercentage: json["redemption_percentage"],
    cancellationCoverage: !exists(json, "cancellation_coverage")
      ? undefined
      : BenefitCancellationCoverageFromJSON(json["cancellation_coverage"]),
    notes: !exists(json, "notes") ? undefined : json["notes"],
    financingMethod: !exists(json, "financing_method")
      ? undefined
      : FinancingMethodsFromJSON(json["financing_method"]),
    financierSellingPrice: !exists(json, "financier_selling_price")
      ? undefined
      : json["financier_selling_price"],
    tukirahoitusMonthlyLeasingPrice: !exists(
      json,
      "tukirahoitus_monthly_leasing_price",
    )
      ? undefined
      : json["tukirahoitus_monthly_leasing_price"],
    tukirahoitusInterestRatesId: !exists(json, "tukirahoitus_interest_rates_id")
      ? undefined
      : json["tukirahoitus_interest_rates_id"],
    fundingSource: !exists(json, "funding_source")
      ? undefined
      : FundingSourceFromJSON(json["funding_source"]),
    isEndedNotificationSent: !exists(json, "is_ended_notification_sent")
      ? undefined
      : json["is_ended_notification_sent"],
    sentNotifications: !exists(json, "sent_notifications")
      ? undefined
      : (json["sent_notifications"] as Array<any>).map(NotificationFromJSON),
    id: json["id"],
    benefitCountry: SupportedOrganisationCountriesFromJSON(
      json["benefit_country"],
    ),
    currentFlatVatRate: json["current_flat_vat_rate"],
    monthlyTaxableValue: json["monthly_taxable_value"],
    fixedMonthlyTaxableValue: !exists(json, "fixed_monthly_taxable_value")
      ? undefined
      : json["fixed_monthly_taxable_value"],
    initialMonthlyTaxableValue: json["initial_monthly_taxable_value"],
    initialFixedMonthlyTaxableValue: !exists(
      json,
      "initial_fixed_monthly_taxable_value",
    )
      ? undefined
      : json["initial_fixed_monthly_taxable_value"],
    leasingPeriodStartDate: new Date(json["leasing_period_start_date"]),
    effectiveEndDate: new Date(json["effective_end_date"]),
    totalPackagePrice: json["total_package_price"],
    taxablePackagePrice: json["taxable_package_price"],
    monthlyLeasingFee: json["monthly_leasing_fee"],
    taxableValue: json["taxable_value"],
    taxablePackagePriceReduction: json["taxable_package_price_reduction"],
    totalMaintenanceBudget: json["total_maintenance_budget"],
    spentMaintenanceBudget: json["spent_maintenance_budget"],
    remainingMaintenanceBudget: json["remaining_maintenance_budget"],
    residualRatio: json["residual_ratio"],
    residualValue: json["residual_value"],
    discountedResidualValue: json["discounted_residual_value"],
    bike: BikeAdminReadFromJSON(json["bike"]),
    accessories: (json["accessories"] as Array<any>).map(AccessoryFromJSON),
    state: BikeBenefitContractStateFromJSON(json["state"]),
    vapausCode: json["vapaus_code"],
    additionalServices: (json["additional_services"] as Array<any>).map(
      AdditionalServiceFromJSON,
    ),
    createdAt: new Date(json["created_at"]),
    updatedAt: new Date(json["updated_at"]),
    transports: (json["transports"] as Array<any>).map(TransportFromJSON),
    deliveryTransport: !exists(json, "delivery_transport")
      ? undefined
      : TransportFromJSON(json["delivery_transport"]),
    correctedByBikeBenefitOrderIds: json["corrected_by_bike_benefit_order_ids"],
    revisedByBikeBenefitOrderIds: json["revised_by_bike_benefit_order_ids"],
    cancellationCoverageStatus: BenefitCancellationCoverageStatusFromJSON(
      json["cancellation_coverage_status"],
    ),
    remainingLeasingPeriodMonths: json["remaining_leasing_period_months"],
    currentRedemptionProcessId: !exists(json, "current_redemption_process_id")
      ? undefined
      : json["current_redemption_process_id"],
    currentReturnProcessId: !exists(json, "current_return_process_id")
      ? undefined
      : json["current_return_process_id"],
    user: UserSummaryExtendedReadFromJSON(json["user"]),
    benefitDefinition: BenefitDefinitionSummaryReadFromJSON(
      json["benefit_definition"],
    ),
    beneficiaryEmail: json["beneficiary_email"],
  };
}

export function BikeBenefitContractProviderReadToJSON(
  value?: BikeBenefitContractProviderRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    delivery_date: value.deliveryDate.toISOString().substring(0, 10),
    start_date: value.startDate.toISOString().substring(0, 10),
    end_date: value.endDate.toISOString().substring(0, 10),
    exceptional_end_date:
      value.exceptionalEndDate === undefined
        ? undefined
        : value.exceptionalEndDate.toISOString().substring(0, 10),
    cancellation_reason: BikeBenefitContractCancellationReasonToJSON(
      value.cancellationReason,
    ),
    cancellation_reason_description: value.cancellationReasonDescription,
    is_invalidated: value.isInvalidated,
    leasing_period_months: value.leasingPeriodMonths,
    user_id: value.userId,
    bike_benefit_order_id: value.bikeBenefitOrderId,
    benefit_definition_id: value.benefitDefinitionId,
    benefit_definition_terms_id: value.benefitDefinitionTermsId,
    bike_id: value.bikeId,
    bike_price: value.bikePrice,
    currency: CurrencyToJSON(value.currency),
    insurance_fee: value.insuranceFee,
    net_fixed_monthly_taxable_value: value.netFixedMonthlyTaxableValue,
    monthly_maintenance_budget: value.monthlyMaintenanceBudget,
    initial_total_package_price: value.initialTotalPackagePrice,
    net_taxable_package_price: value.netTaxablePackagePrice,
    net_monthly_leasing_fee: value.netMonthlyLeasingFee,
    net_monthly_taxable_value: value.netMonthlyTaxableValue,
    net_taxable_value: value.netTaxableValue,
    initial_vat_rate: value.initialVatRate,
    net_residual_value: value.netResidualValue,
    down_payment_amount: value.downPaymentAmount,
    extended_warranty: value.extendedWarranty,
    is_down_payment_insured: value.isDownPaymentInsured,
    yearly_down_payment_insurance_fee: value.yearlyDownPaymentInsuranceFee,
    redemption_percentage: value.redemptionPercentage,
    cancellation_coverage: BenefitCancellationCoverageToJSON(
      value.cancellationCoverage,
    ),
    notes: value.notes,
    financing_method: FinancingMethodsToJSON(value.financingMethod),
    financier_selling_price: value.financierSellingPrice,
    tukirahoitus_monthly_leasing_price: value.tukirahoitusMonthlyLeasingPrice,
    tukirahoitus_interest_rates_id: value.tukirahoitusInterestRatesId,
    funding_source: FundingSourceToJSON(value.fundingSource),
    is_ended_notification_sent: value.isEndedNotificationSent,
    sent_notifications:
      value.sentNotifications === undefined
        ? undefined
        : (value.sentNotifications as Array<any>).map(NotificationToJSON),
    id: value.id,
    benefit_country: SupportedOrganisationCountriesToJSON(value.benefitCountry),
    current_flat_vat_rate: value.currentFlatVatRate,
    monthly_taxable_value: value.monthlyTaxableValue,
    fixed_monthly_taxable_value: value.fixedMonthlyTaxableValue,
    initial_monthly_taxable_value: value.initialMonthlyTaxableValue,
    initial_fixed_monthly_taxable_value: value.initialFixedMonthlyTaxableValue,
    leasing_period_start_date: value.leasingPeriodStartDate
      .toISOString()
      .substring(0, 10),
    effective_end_date: value.effectiveEndDate.toISOString().substring(0, 10),
    total_package_price: value.totalPackagePrice,
    taxable_package_price: value.taxablePackagePrice,
    monthly_leasing_fee: value.monthlyLeasingFee,
    taxable_value: value.taxableValue,
    taxable_package_price_reduction: value.taxablePackagePriceReduction,
    total_maintenance_budget: value.totalMaintenanceBudget,
    spent_maintenance_budget: value.spentMaintenanceBudget,
    remaining_maintenance_budget: value.remainingMaintenanceBudget,
    residual_ratio: value.residualRatio,
    residual_value: value.residualValue,
    discounted_residual_value: value.discountedResidualValue,
    bike: BikeAdminReadToJSON(value.bike),
    accessories: (value.accessories as Array<any>).map(AccessoryToJSON),
    state: BikeBenefitContractStateToJSON(value.state),
    vapaus_code: value.vapausCode,
    additional_services: (value.additionalServices as Array<any>).map(
      AdditionalServiceToJSON,
    ),
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt.toISOString(),
    transports: (value.transports as Array<any>).map(TransportToJSON),
    delivery_transport: TransportToJSON(value.deliveryTransport),
    corrected_by_bike_benefit_order_ids: value.correctedByBikeBenefitOrderIds,
    revised_by_bike_benefit_order_ids: value.revisedByBikeBenefitOrderIds,
    cancellation_coverage_status: BenefitCancellationCoverageStatusToJSON(
      value.cancellationCoverageStatus,
    ),
    remaining_leasing_period_months: value.remainingLeasingPeriodMonths,
    current_redemption_process_id: value.currentRedemptionProcessId,
    current_return_process_id: value.currentReturnProcessId,
    user: UserSummaryExtendedReadToJSON(value.user),
    benefit_definition: BenefitDefinitionSummaryReadToJSON(
      value.benefitDefinition,
    ),
    beneficiary_email: value.beneficiaryEmail,
  };
}
