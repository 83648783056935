import { useTranslation } from "react-i18next";

import { SupportedOrganisationCountries } from "@vapaus/api-codegen";
import { Flex, RowItem, Tooltip } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  useDateFormat,
} from "../../../../../../../../../packages/i18n/src/hooks";
import { ContractParamsProps, ContractProps } from "../../../type";

interface ContractSummaryProps extends ContractProps {
  contractParams: ContractParamsProps;
}

export function ContractSummary({
  contract,
  contractParams,
}: ContractSummaryProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(contract.currency);
  const formatDate = useDateFormat();
  const isSwedishContract =
    contract.benefitCountry === SupportedOrganisationCountries.Se;

  return (
    <Flex direction="column" gap="md" marginBottom="md">
      <RowItem
        label={t("admin:contractDetails.summary.startDate")}
        value={formatDate(contract.startDate)}
      />
      <RowItem
        label={t("admin:contractDetails.summary.endDate")}
        value={formatDate(contract.effectiveEndDate)}
      />
      <RowItem
        label={t("admin:contractDetails.summary.leasingPeriod.name")}
        value={t("admin:contractDetails.summary.leasingPeriod.content", {
          months: contract.leasingPeriodMonths,
        })}
      />
      <RowItem
        label={
          isSwedishContract
            ? t("admin:contractDetails.summary.monthlyRentalFee")
            : t("admin:contractDetails.summary.monthlyPayment")
        }
        value={
          <Flex>
            {formatCurrency(
              contract.fixedMonthlyTaxableValue || contract.monthlyTaxableValue,
            )}
            {isSwedishContract ? (
              <Tooltip>
                {t("admin:contractDetails.summary.monthlyRentalFeeTooltip")}
              </Tooltip>
            ) : null}
          </Flex>
        }
      />
      <RowItem
        label={t("admin:contractDetails.summary.totalContractValue")}
        value={formatCurrency(contractParams.contractTotal)}
        valueIsBold
      />
    </Flex>
  );
}
